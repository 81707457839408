import Navbar from "./components/navbar";
import Footer from "./components/footer";

import Home from "./pages/home";
import About from "./pages/about";
import Joy from "./pages/platforms/joy";
import Arkham from "./pages/platforms/arkham";
import Findings from "./pages/findings";
import Start from "./pages/start";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Meta from "./img/meta.png";

import "./App.scss";

function App() {
  return (
    <Router>
      <img src={Meta} alt="" style={{ display: "none" }} />
      <div style={{ minHeight: "95vh" }}>
        <Navbar />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/platforms/joy">
            <Joy />
          </Route>
          <Route path="/platforms/arkham">
            <Arkham />
          </Route>
          <Route path="/findings">
            <Findings />
          </Route>
          <Route path="/start">
            <Start />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
