import React from "react";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "../../img/logo-black.png";

import styles from "./style.module.scss";
import classnames from "classnames";

const Footer = () => {
  return (
    <Container
      fluid
      className={classnames(
        "py-4",
        "px-4",
        "mt-2",
        "mt-md-4",
        "mt-lg-5",
        styles["footer"]
      )}
    >
      <Row>
        <Col xs={12} lg={4} className={styles["left"]}>
          <Link to="/">Copyright</Link>
          <Link to="/">Privacy</Link>
          <Link to="/">Terms</Link>
        </Col>
        <Col xs={12} lg={4} className={styles["center"]}>
          <img src={Logo} alt="Project AEI" />
        </Col>
        <Col xs={12} lg={4} className={styles["right"]}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/platforms/joy">Platforms</Link>
          <Link to="/findings">Findings</Link>
          <Link to="/start">Waitlist</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
