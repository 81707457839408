import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./style.module.scss";

const Findings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="px-4 px-lg-5">
      <Row className="mt-4 mt-lg-5 pb-4 px-0 px-md-4 px-lg-5">
        <Col>
          <h2 className="subtitle-2">Our Findings.</h2>
          <p className="text mt-4">Coming Soon.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Findings;
