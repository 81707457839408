import React, { useEffect } from "react";

import Button from "../../../components/button";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "./img/arkham.png";
import Walking from "./img/walking.png";

import styles from "./style.module.scss";

const Arkham = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="px-4 px-lg-5 pb-4 pt-1 pt-lg-3">
      <Row className="my-3 my-lg-5 px-3 px-lg-5">
        <Col className={styles["logo"]}>
          <img src={Logo} alt="Arkham Logo" />
        </Col>
      </Row>

      <Row className="mt-4 mt-lg-5 pt-2 pt-lg-4">
        <hr className={styles["line"]} />
      </Row>

      <Row className="my-3 my-lg-5 pb-2 pb-lg-4 px-0 px-lg-5 pt-2">
        <Col>
          <h3 className="subtitle-2 mb-4 pb-2">Coming soon</h3>
          <Button text="Contact Us" to="/start" variant="white" />
        </Col>
      </Row>
      <Row className="py-3 mb-2 mb-lg-5">
        <img src={Walking} alt="Arkham" />
      </Row>
      <Row className="pt-3">
        <Col>
          <p className="text">
            Arkham is a software that further assures the safety of law
            enforcement officers and the citizens that they deal with via the
            prediction of physical violence in real-time, and the provision of a
            means of “seeing through the eyes of the officer in the field” so
            that the department or organization to which the officer or
            individual in question belongs can limit liability, and assure the
            safety of both the public and their officers.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Arkham;
