import React, { useState, useEffect, useRef } from "react";


import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Graphic from "./img/graphic.png";

import styles from "./style.module.scss";
import classnames from "classnames";


const Start = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [products, setProducts] = useState("");
  const [productsError, setProductsError] = useState(false);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [site, setSite] = useState("");
  const [siteError, setSiteError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [heard, setHeard] = useState("");
  const [heardError, setHeardError] = useState(false);
  const [message, setMessage] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef(null);
  const scriptUrl = 'https://script.google.com/macros/s/AKfycbytngoNkWNbPPjJEGY5ErX8VwevlJUmhpR8imuP1VPmHaYidZK2Csg5gzpAmvSBDTGO/exec';
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const valid =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(valid)) {
      return true;
    }
    return false;
  };

  const lenCheck = (check, func) => {
    if (check.length === 0) {
      func(true);
      return false;
    } else {
      func(false);
      return true;
    }
  };

  const emailCheck = () => {
    if (validateEmail(email)) {
      setEmailError(false);
      return true;
    } else {
      setEmailError(true);
      return false;
    }
  };

  const Submit = (e) => {
    e.preventDefault()
    const test1 = lenCheck(name, setNameError);
    const test2 = emailCheck();
    const test3 = lenCheck(products, setProductsError);
    // const test4 = lenCheck(company, setCompanyError);
    // const test5 = lenCheck(site, setSiteError);
    // const test6 = lenCheck(title, setTitleError);
    const test7 = lenCheck(heard, setHeardError);
    //add this back into conditional && test4 && test5 && test6
    if (test1 && test2 && test3 && test7) {
      const data = new FormData(formRef.current);
      for (var [key, value] of data.entries()) { 
        console.log(key, value);
       }
      fetch(scriptUrl, {crossDomain:true, method: 'POST', body: data})
      .then(res => {
        if(res.status==200){
          console.log("SUCCESSFULLY SUBMITTED");
          console.log(res.text());
        }
          
      })
      .catch(err => console.log(err))
      document.getElementById("submit-button").type = "submit";
      setTimeout(function () {
        document.getElementById("submit-button").type = "button";
        setSubmitted(true);
      }, 2000);
    } else {
      console.log("failure");
    }

  };



  return (
    <>
      <Container className="px-4 px-lg-5">
        <Row className="mt-4 mt-lg-5 mb-3 pb-4 pb-lg-5 px-0 px-md-4 px-lg-5">
          <Col>
            <h2 className="subtitle-2">Get started and join our waitlist</h2>
            <p className="text">
              Tell us what you’re interested in and we’ll get back to you!
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className={styles["graphic"]}>
        <img src={Graphic} alt="Join Waitlist" />
      </Container>
      <Container className="px-4 px-lg-5 mt-3 mt-md-4 mt-lg-5 pt-4">
        <Row className="mb-3 mb-md-4">
          <Col>
            <p className={classnames("text", styles["description"])}>
              Joining our waitlist signs you up for updates regarding our
              products and gives you access to our beta.
            </p>
          </Col>
        </Row>

        {submitted ? (
          <Row className="mb-3 mb-md-4">
            <Col>
              <p className="text">
                Thank you for signing up for our waitlist! We'll be sure to
                contact you through your email once we're closer to launch.
              </p>
            </Col>
          </Row>
        ) : (
        	//  <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100%'}}>
        	//  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc520hmwkjz1On5YWj-EjfjsUPnjA0JGdakzXLbBzvDCdg22Q/viewform?embedded=true"  frameborder="0" marginheight="0" marginwidth="0" sandbox='allow-scripts allow-modal' width="1500" height="1800">
          // 	</iframe>
          // 	</div>
          // <RGF config={formConfig} />
          <Form
            ref={formRef}
            // action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSc520hmwkjz1On5YWj-EjfjsUPnjA0JGdakzXLbBzvDCdg22Q/formResponse"
            // target="_blank"
            // method="POST"
          >
            <Row className="mb-4 pb-2">
              <Col xs={12} md={6} className="mb-4 pb-2 mb-md-0 pb-md-0">
                <Form.Group controlId="name">
                  <Form.Label className="text-small">Full Name</Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="Full Name"
                  />
                  {nameError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a name.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="email">
                  <Form.Label className="text-small">Email Address</Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="Email Address"
                  />
                  {emailError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a valid email.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4 pb-2">
              <Col>
                <Form.Group controlId="products">
                  <Form.Label className="text-small">
                    What product(s) are you interested in?
                  </Form.Label>
                  <Form.Select
                    aria-label="products"
                    className={styles["input"]}
                    value={products}
                    onChange={(e) => setProducts(e.target.value)}
                    name="What product(s) are you interested in?"
                  >
                    <option value=""></option>
                    <option value="Joy">Joy</option>
                    <option value="Arkham">Arkham</option>
                    <option value="Both Joy and Arkham">
                      Both Joy and Arkham
                    </option>
                    <option value="Unsure">Unsure</option>
                  </Form.Select>
                  {productsError && (
                    <Form.Text className={styles["error"]}>
                      Please choose an option.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row className="mb-4 pb-2">
              <Col xs={12} md={6} className="mb-4 pb-2 mb-md-0 pb-md-0">
                <Form.Group controlId="company-name">
                  <Form.Label className="text-small">Company Name</Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    name="Company Name"
                  />
                  {companyError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a company name.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="company-site">
                  <Form.Label className="text-small">Company Site</Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="text"
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                    name="Company Site"
                  />
                  {siteError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a company site.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4 pb-2">
              <Col>
                <Form.Group controlId="title">
                  <Form.Label className="text-small">
                    Title & Department
                  </Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    name="Title & Department"
                  />
                  {titleError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a title & department.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row> */}

            <Row className="mb-4 pb-2">
              <Col>
                <Form.Group controlId="referral">
                  <Form.Label className="text-small">
                    How did you hear about us?
                  </Form.Label>
                  <Form.Control
                    className={styles["input"]}
                    type="text"
                    value={heard}
                    onChange={(e) => setHeard(e.target.value)}
                    name="How did you hear about us?"
                  />
                  {heardError && (
                    <Form.Text className={styles["error"]}>
                      Please enter a referral.
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4 pb-4 pb-md-5">
              <Col>
                <Form.Group controlId="message">
                  <Form.Label className="text-small">
                    Personal Message (optional)
                  </Form.Label>
                  <Form.Control
                    className={styles["textarea"]}
                    as="textarea"
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="Personal Message (optional)"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="pb-5">
              <Col className={styles["submit"]}>
                <button
                  type="button"
                  id="submit-button"
                  onClick={Submit}
                  className={styles["button"]}
                >
                  <p>ADD ME TO THE WAITLIST</p>
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default Start;
