import React from "react";

import { Link, NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import Logo from "../../img/logo-blue.png";

import styles from "./style.module.scss";
import classnames from "classnames";
import "./dropdown.scss";

const SiteNavbar = () => {
  return (
    <Navbar
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(50px)",
      }}
      expand="lg"
      className="navbar"
      sticky="top"
    >
      <Container fluid className={styles["navbar"]}>
        <Link to="/" className={styles["logo"]}>
          <img src={Logo} alt="Project AEI" />
        </Link>
        <Navbar.Toggle aria-controls="nav-container" />
        <Navbar.Collapse id="nav-container" className={styles["nav-container"]}>
          <Nav className={styles["nav"]}>
            <NavLink
              exact
              to="/"
              className={styles["nav-link"]}
              activeClassName={styles["active"]}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={styles["nav-link"]}
              activeClassName={styles["active"]}
            >
              About
            </NavLink>

            <NavDropdown
              title="Platforms"
              id="platforms"
              className={styles["desktop"]}
            >
              <NavDropdown.Item href="/platforms/joy">
                <NavLink
                  to="/platforms/joy"
                  className={styles["nav-link"]}
                  activeClassName={styles["active"]}
                >
                  Joy
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="/platforms/arkham">
                <NavLink
                  to="/platforms/arkham"
                  className={styles["nav-link"]}
                  activeClassName={styles["active"]}
                >
                  Arkham
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink
              to="/platforms/joy"
              className={classnames(styles["nav-link"], styles["mobile"])}
              activeClassName={styles["active"]}
            >
              Joy
            </NavLink>
            <NavLink
              to="/platforms/arkham"
              className={classnames(styles["nav-link"], styles["mobile"])}
              activeClassName={styles["active"]}
            >
              Arkham
            </NavLink>

            <NavLink
              to="/findings"
              className={styles["nav-link"]}
              activeClassName={styles["active"]}
            >
              Findings
            </NavLink>
            <NavLink
              to="/start"
              className={styles["nav-link"]}
              activeClassName={styles["active-button"]}
            >
              <div className={styles["button"]}>
                <p>Join Waitlist</p>
              </div>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SiteNavbar;
