import React from "react";

import { Link } from "react-router-dom";

import styles from "./style.module.scss";

const Button = ({ text, to, variant }) => {
  return (
    <Link to={to} className={styles["button"]}>
      <button className={styles[`button-${variant}`]}>
        <p>{text}</p>
      </button>
    </Link>
  );
};

export default Button;
