import React from "react";

import Button from "../button";

import Col from "react-bootstrap/Col";

import styles from "./style.module.scss";
import classnames from "classnames";

const Box = ({ text, subtitle }) => {
  return (
    <Col className={styles["summary"]}>
      <h5 className={styles["description"]}>{text}</h5>
      {subtitle && <h6 className="text-small">{subtitle}</h6>}
      <Button text="Join Waitlist" to="/start" variant="white" />
    </Col>
  );
};

export default Box;
