import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Urban from "./img/urban.png";
import Coding from "./img/coding.png";
import Team from "./img/team.png";
import Mind from "./img/mind.png";
import Person from "./img/person.png";

import styles from "./style.module.scss";
import classnames from "classnames";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className={styles["splash"]}>
          <img src={Urban} alt="landing graphic" />
        </Row>
      </Container>
      <Container className="px-3 px-lg-5">
        <Row className="py-4 py-lg-5 my-4">
          <h4 className={classnames("subtitle-3", styles["subtitle"])}>
            We’re a team dedicated to meaningful software.
          </h4>
        </Row>
      </Container>
      <Container fluid>
        <Row className={styles["page-graphic"]}>
          <img src={Team} alt="Who We Are" />
        </Row>
      </Container>
      <Container className="px-4 px-lg-5 my-3 py-2">
        <Row className="my-3 pt-3 pt-lg-5 pb-4 pb-lg-0">
          <Col xs={12} md={7} className={styles["about"]}>
            <h3 className="subtitle-3">Who We Are</h3>
            <p className="text-small">
              Project AEI began with one simple, but also extremely complex,
              goal: create software that can make sense of the vocally expressed
              emotions of every individual in the world and use these emotional
              expressions to predict impending violence and malintent, and bring
              people closer to the mental health resources that they need to
              live happier, healthier lives.
            </p>
          </Col>
          <Col xs={12} md={5} className={styles["about-image"]}>
            <img src={Coding} alt="Team Graphic" />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className={styles["page-graphic"]}>
          <img src={Mind} alt="Our Mission" />
        </Row>
      </Container>
      <Container className="px-4 px-lg-5 my-3 py-2">
        <Row className="my-3">
          <Col>
            <h3 className="subtitle-3">Our Mission</h3>
            <p className={classnames("text-small", styles["description"])}>
              We wanted to give people access to a technological resource that
              does something more than the conventional tech product on the
              market does. We wanted to create a product that augments the
              totality of the human experience while both guiding people through
              the arduous steps of their own personal development, and further
              ensuring their safety as members of the public.
              <br />
              <br />
              The ultimate product of all of our efforts is our AEI Engine, a
              machine learning algorithm that enables Arkham to predict violent
              human behaviour before it happens in order to save lives in the
              fields of police work and other forms of public service, and
              enables Joy to provide users with the mental health and
              mindfulness resources that are best suited for their real-time
              emotional needs.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="pb-2">
        <Row className={styles["page-graphic"]}>
          <img src={Person} alt="People Graphic" className="mb-5 mb-md-0" />
        </Row>
      </Container>
    </>
  );
};

export default About;
