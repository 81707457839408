import React, { useEffect } from "react";

import Button from "../../components/button";
import Box from "../../components/box";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Poster from "./img/poster.png";
import Arkham from "./img/arkham.png";
import Joy from "./img/joy.png";
import Walking from "./img/walking.png";

import styles from "./style.module.scss";
import classnames from "classnames";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="px-4 px-lg-5 mt-2 mt-lg-5 pb-4">
      <Row>
        <Col>
          <h2 className="subtitle-2 mb-3">
            Software that knows how you feel.
            <br />
            Before you do.
          </h2>

          <video
            poster={Poster}
            controlsList="nodownload"
            autoplay="autoplay"
            muted
            loop
            className={styles["footage"]}
          >
            <source
              src="https://aei-bucket.s3.us-east-2.amazonaws.com/aei-video.mp4"
              type="video/mp4"
            />
          </video>

          <h5 className={classnames(styles["subtitle"], "mt-2 mb-2")}>
            We build software that brings <br />
            emotionality into the 21st century.
          </h5>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h4 className="subtitle-2 mb-3 mb-lg-5 pb-1">Our Products</h4>
          <hr className={styles["line"]} />
        </Col>
      </Row>
      <Row className="mt-1 mt-lg-3 mb-1 px-5">
        <Col xs={12} md={6}>
          <img src={Arkham} alt="arkham" className={styles["product"]} />
        </Col>
        <Col xs={12} md={6} className={styles["description"]}>
          <h5 className="subtitle-3">Arkham</h5>
          <p className="text mt-1 mb-3">
            A means of reigning in violence, and further ensuring the safety of
            the public.
          </p>
          <Button text="Explore Arkham" to="/platforms/arkham" variant="blue" />
        </Col>
      </Row>
      <Row className="pt-4 pt-lg-0 pb-4 px-5">
        <Col xs={12} className={styles["mobile"]}>
          <img src={Joy} alt="joy" className={styles["product"]} />
        </Col>
        <Col xs={12} md={6} className={styles["description"]}>
          <h5 className="subtitle-3">Joy</h5>
          <p className="text mt-1 mb-3">
            A mindfulness app that delivers content based on real-time emotional
            needs.
          </p>
          <Button text="Explore Joy" to="/platforms/joy" variant="blue" />
        </Col>
        <Col md={6} className={styles["desktop"]}>
          <img src={Joy} alt="joy" className={styles["product"]} />
        </Col>
      </Row>
      <Row className="mt-4 pt-2 pb-4 pb-lg-5">
        <img src={Walking} alt="people walking" className={styles["walking"]} />
      </Row>
      <Row className="mt-3 mt-lg-5 pt-3">
        <Box
          text="We are the first organization to create software that can deliver personalized mental health resources to users and further assure public safety via violence prediction, all based on real-time emotional profiles."
          subtitle="Project AEI is redefining the role of human emotions in everyday life"
        />
      </Row>
    </Container>
  );
};

export default Home;
