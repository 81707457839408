import React, { useState, useEffect } from "react";

import Box from "../../../components/box";

import Carousel, { Dots, autoplayPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Graphic from "./img/graphic.png";
import Phone from "./img/phone.png";
import Talking from "./img/talking.png";
import imageOne from "./img/carousel/imageOne.png";
import imageTwo from "./img/carousel/imageTwo.png";
import imageThree from "./img/carousel/imageThree.png";
import imageFour from "./img/carousel/imageFour.png";
import imageFive from "./img/carousel/imageFive.png";

import styles from "./style.module.scss";
import classnames from "classnames";

const Joy = () => {
  const [value, setValue] = useState(0);
  const [slides, setSlides] = useState([
    <img src={imageOne} alt="Yoga" />,
    <img src={imageTwo} alt="Analytics" />,
    <img src={imageThree} alt="Biking" />,
    <img src={imageFour} alt="Phone" />,
    <img src={imageFive} alt="Skydiving" />,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = (value) => {
    setValue(value);
  };

  return (
    <>
      <Container fluid className={styles["splash"]}>
        <img src={Graphic} alt="Joy" />
      </Container>
      <Container className="px-4 px-lg-5 py-4 mt-1">
        <Row className="mt-4 mt-lg-5 pb-4 px-3 px-lg-5">
          <Col>
            <h2 className="subtitle-2">Mindfulness reimagined.</h2>
            <p className="text mt-4">
              Joy is an artificial emotional intelligence (AEI) powered
              mindfulness platform that offers users access to a large variety
              of both contemplative and traditional, interactive, mindfulness
              practices that are presented according to the user’s real-time
              emotional needs; Joy also presents the user with the opportunity
              to see how these practices affect their emotional states after
              engagement and over time.
            </p>
          </Col>
        </Row>
        <Row className="mt-1 mt-lg-5 pt-1 pt-lg-4">
          <hr className={styles["line"]} />
        </Row>
        <Row className={styles["graphic"]}>
          <Carousel
            value={value}
            slides={slides}
            onChange={onChange}
            plugins={[
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 3000,
                },
              },
            ]}
            animationSpeed={1500}
            draggable={false}
          />
          <Dots value={value} onChange={onChange} number={slides.length} />
        </Row>
        <Row className="mt-3 mb-3 mb-md-3 mb-lg-5 pb-3">
          <Col xs={12} md={7} className={styles["text-left"]}>
            <h4 className="subtitle-3 mb-3">How Does This Happen?</h4>
            <p className="text-small mt-0 mt-md-2 mt-lg-3">
              Users have the ability to simply tell Joy how they feel using
              their voice, and it will present them with an assortment of the
              content that is best suited for their emotional reality, and after
              they engage with our practices users have the opportunity to once
              again tell us how our practice made them feel so that we can take
              this information and provide a seamless means of tracking their
              overall level of Joy.
            </p>
          </Col>
          <Col xs={12} md={5} className={styles["image"]}>
            <img src={Phone} alt="How Does This Happen?" />
          </Col>
        </Row>
        <Row className="pt-2 pt-md-3 pt-lg-5">
          <Col
            xs={5}
            className={classnames(styles["image"], styles["desktop"])}
          >
            <img src={Talking} alt="How Are We Different?" />
          </Col>
          <Col xs={12} md={7} className={styles["text-right"]}>
            <h4 className="subtitle-3 mb-3">How Are We Different?</h4>
            <p className="text-small mt-3">
              Our content defies the conventions of the public’s traditional
              understanding of mindfulness. We’re focused on taking actionable
              steps towards improving your life in tangible ways. You will be
              able to visually and physically see the impact that your
              engagement with our practices has had on your life. Our content is
              also research-based, has been empirically tested, and has been
              proven to bring people from negative states of being to positive
              states of being.
            </p>
          </Col>
          <Col
            xs={12}
            className={classnames(styles["image"], styles["mobile"], "mb-5")}
          >
            <img src={Talking} alt="How Are We Different?" />
          </Col>
        </Row>
        <Row className="mt-md-4 mt-lg-5 pt-md-4 pt-lg-5 mb-4">
          <Box text="At the heart of our business, we are an A.I. Voice Biomarker Technology Company. We take great care in quantifying how our practices make our users feel and how users shape the forefront of their experience with our product." />
        </Row>
      </Container>
    </>
  );
};

export default Joy;
